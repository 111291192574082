<template>
  <header v-click-outside="onClickOutside" class="header">
    <div class="container">
      <!-- first row  -->
      <div class="header_row">
        <!-- burger -->
        <div class="burger" :class="{ open: burgerOpen }" @click="burgerOpen = !burgerOpen">
          <span class="top"></span>
          <span class="mid"></span>
          <span class="bot"></span>
        </div>
        <!-- burger -->

        <!-- Gerb -->
        <router-link class="header_logo-container" to="/">
          <img class="header_logo" src="~@/assets/img/gerb.png" alt="Герб РФ" />
        </router-link>
        <!-- Gerb -->

        <!-- main title -->
        <router-link class="header_title" to="/">
          <h1>Единый портал внешнеэкономической информации Минэкономразвития России</h1>
        </router-link>
        <!-- main title -->

        <div class="header_auth-container">
          <!-- bvi -->
          <VisuallyImpaired class="ml-2" />
          <!-- bvi -->
          <!-- profile icon and exit -->
          <template v-if="!$store.state.auth?.oidc?.isLoggedOn">
            <div class="header_auth-login">
              <router-link class="header_auth-link" to="/auth" title="Войти">
                <IconComponent name="person" />
              </router-link>
            </div>
          </template>
          <template v-else>
            <div class="header_auth-login">
              <router-link to="/account-settings" title="Настройки">
                <IconComponent name="personAuth" />
              </router-link>
              <router-link to="/logout"> Выйти </router-link>
            </div>
          </template>
          <!-- profile icon and exit -->
          <!-- switcher lang -->
          <SwitchComponentHeader class="switcher-container" textTrue="Ru" textFalse="En" />
          <!-- switcher lang -->
        </div>
      </div>
      <!-- first row  -->
    </div>
    <!-- second row -->
    <!-- transition group -->
    <section class="header_menu-section" :class="{ open: burgerOpen }">
      <div class="container">
        <div class="header_auth-container">
          <!-- bvi -->
          <VisuallyImpaired class="ml-2" />
          <!-- bvi -->
          <!-- profile icon and exit -->
          <template v-if="!$store.state.auth?.oidc?.isLoggedOn">
            <div class="header_auth-login">
              <router-link class="header_auth-link" to="/auth" title="Войти">
                <img src="~@/assets/img/svg/personMob.svg" />
              </router-link>
            </div>
          </template>
          <template v-else>
            <div class="header_auth-login">
              <router-link to="/account-settings" title="Настройки">
                <img src="~@/assets/img/svg/personAuth.svg" />
              </router-link>
              <router-link to="/logout"> Выйти </router-link>
            </div>
          </template>
          <!-- profile icon and exit -->
          <!-- switcher lang -->
          <SwitchComponentHeader class="switcher-container" textTrue="Ru" textFalse="En" />
          <!-- switcher lang -->
        </div>
        <nav class="header_menu-main-container">
          <ul class="header_menu-main">
            <li
              class="header_menu-main-item"
              v-for="(headerItem, index) in headerMenu"
              :key="index"
              @click="burgerOpen = false"
            >
              <div class="header_menu-main-itemContainer" :class="{ openMobileMenu: openMobilSubMenuIndex == index }">
                <template v-if="headerItem.id == 23">
                  <a :href="headerItem.link" target="_blank" class="header_menu-main-itemLink">{{ headerItem.name }}</a>
                </template>
                <template v-else>
                  <a v-if="headerItem.link.startsWith('http')" :href="headerItem.link" target="_blank">{{
                    headerItem.name
                  }}</a>
                  <router-link
                    v-else
                    :to="headerItem.link"
                    class="header_menu-main-itemLink"
                    :class="{ 'router-link-active': isActiveMenu(headerItem.link) }"
                  >
                    {{ headerItem.name }}
                  </router-link>
                </template>
              </div>

              <ul class="header_menu-sub" v-if="headerItem.submenu && headerItem.submenu.length > 0">
                <li
                  class="header_menu-sub-item"
                  v-for="(header, submenuIndex) in headerItem.submenu"
                  :key="submenuIndex"
                >
                  <router-link
                    :to="header.link"
                    :class="{ 'router-link-active': isActiveMenu(header.link, 'submenu') }"
                    >{{ header.name }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <!-- transition group -->
    <!-- second row -->
  </header>
</template>

<script>
  import vClickOutside from 'click-outside-vue3';

  import IconComponent from '@/app/shared/components/ui/IconComponent';

  import { menuActive } from '@/common/mixins/menu-active.js';

  import VisuallyImpaired from './VisuallyImpaired.vue';
  import API from './api/menu';
  import SwitchComponentHeader from './ui/switchComponentHeader.vue';

  export default {
    // eslint-disable-next-line
    name: 'App-header',
    components: {
      IconComponent,
      VisuallyImpaired,
      SwitchComponentHeader,
    },
    data() {
      return {
        burgerOpen: false,
        openMobilSubMenuIndex: null,
        headerMenu: [],
      };
    },
    created() {
      this.loadMenu();
    },
    mounted() {
      setTimeout(() => {
        //получилось сделать ассинхроно тут так
        menuActive(this.$route.href);
      }, 500);
    },

    methods: {
      loadMenu() {
        API.getMenu().then((response) => {
          this.headerMenu = response.data;
        });
      },
      // openMobilSubMenu(index) {
      //   if (this.openMobilSubMenuIndex != index) {
      //     this.openMobilSubMenuIndex = index;
      //   } else {
      //     this.openMobilSubMenuIndex = null;
      //   }
      // },
      // для определения родительских пунктов, активного подпункта в подменю,
      // так как не используется массив children в роуетерах, то апктивный класс не проставляется на такие линки/:id
      isActiveMenu(path, typeLink) {
        let numInUrl = typeLink == 'submenu' ? 2 : 1,
          link = this.$route.path.split('/')[numInUrl] === '' ? 'main' : this.$route.path.split('/')[numInUrl],
          currentPath = path.split('/')[numInUrl] === '' ? 'main' : path.split('/')[numInUrl];
        return link?.startsWith(currentPath);
      },
      onClickOutside() {
        this.burgerOpen = false;
      },
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
  };
</script>

<style lang="sass">
  @import "./styles/header.sass"
  .logout
    margin-left: 13px

    .fa-sign-out-alt
      color: rgba(33, 33, 37, 0.5)
      font-size: 22px
      transition: color 0.2s ease-in-out

      &:hover
        color: rgba(33, 33, 37, 1)
</style>
